import I18n from './i18n'

export default {
  appName: '我愛工作',

  'slogan.default': `**優秀**|是一種習慣
**優秀**|是與生俱來的天賦
**我愛工作**
工作使我快樂
我徜徉在工作的海洋裡
上司叫我吃飯|我充耳不聞
同事喊我喝水|我無動於衷
老闆喊我睡覺|我百般推辭`,
  'action.ok': '好的',
  'action.save': '保存',
  'action.save.success': '保存成功',
  'action.cancel': '取消',
  'action.reset': '重置',

  settings: '設置',
  'settings.application': '應用程序',
  'settings.appearance': '界面',
  'settings.language': '語言',
  'settings.language.default': '跟隨系統',
  'settings.about': '關於',
  'settings.slogan': '標語',
  'settings.slogan.syntax': '語法說明',
  'settings.slogan.syntax.summary': `一行語句為一段，使用 | 進行段內分行

另外支持使用部分簡單的 Markdown 語法：

\`**加粗**\` ⇨ **加粗**

\`*斜體*\` ⇨ *斜體*

\`~~刪除~~\` ⇨ ~~刪除~~`,
  'settings.slogan.custom': '自定義標語',
  'settings.slogan.interval': '時間間隔',
  'settings.about.release_note': '更新日誌',
  'settings.appearance.theme': '主題',
  'settings.appearance.theme.white': '白 色',
  'settings.appearance.theme.black': '黑 色',
  'settings.appearance.theme.light': '亮 色',
  'settings.appearance.theme.dark': '暗 色',
  'settings.appearance.theme.chrome-dark': 'Chrome(暗)',
  'settings.appearance.theme.jike-yellow': '即刻黄',
} as I18n
